import React from 'react'
import { SpecialMenuAvailability } from '../../FulfillmentDisplay/SpecialMenuFulfillmentDisplay'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { DiningOptionBehavior } from '../../../types/cart'

export const FulfillmentHeader: React.FC<{
  editing: boolean
  displayName: string | undefined
  behavior: DiningOptionBehavior | undefined
}> = ({ editing, displayName, behavior }) => {
  const {
    applicableConfigs: { brandingConfig }
  } = useRestaurant()
  return (
    <>
      <h2
        data-testid='scheduled-modal-header'
        className='pb-2 font-semibold type-large'
      >
        {displayName ||
          `${editing ? 'Update' : 'Find a time for'} your ${
            brandingConfig?.removeCateringName ? 'order' : 'catering order'
          }`}
      </h2>
      {displayName ? (
        <SpecialMenuAvailability
          className='pb-4 font-normal type-subhead'
          behavior={behavior}
          showCurrentAvailability
        />
      ) : (
        <p className='pb-4 font-normal type-subhead'>
          Fill out these details to be able to add items to your cart, or browse
          the menu and enter details later!
        </p>
      )}
    </>
  )
}
